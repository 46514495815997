import "./bootstrap.js";
import "./swup.js";

import ui from "@alpinejs/ui";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import brandMenuDesktop from "./components/brandMenuDesktop.js";
import brandMenuMobile from "./components/brandMenuMobile.js";
import newsletterForm from "./components/newsletterForm.js";
import brandHeader from "./components/brandHeader.js";
import brandMixer from "./components/brandMixer.js";
import accordion from "./components/accordion.js";
import storyGrid from "./components/storyGrid.js";
import homeMenu from "./components/homeMenu.js";
import search from "./components/search.js";
import newIn from "./components/newIn.js";
import map from "./components/map.js";

import brandMenu from "./stores/brandMenu.js";
import breakpoint from "./stores/breakpoint.js";

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(ui);

document.addEventListener("alpine:init", () => {
    Alpine.data("brandMenuDesktop", brandMenuDesktop);
    Alpine.data("brandMenuMobile", brandMenuMobile);
    Alpine.data("newsletterForm", newsletterForm);
    Alpine.data("brandHeader", brandHeader);
    Alpine.data("brandMixer", brandMixer);
    Alpine.data("accordion", accordion);
    Alpine.data("storyGrid", storyGrid);
    Alpine.data("homeMenu", homeMenu);
    Alpine.data("search", search);
    Alpine.data("newIn", newIn);
    Alpine.data("map", map);

    Alpine.store("brandMenu", brandMenu);
    Alpine.store("breakpoint", breakpoint);
});

Alpine.start();

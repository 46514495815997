export default () => ({
    open: null,

    init() {
        this.$watch("open", () => {
            if (this.open) {
                setTimeout(() => {
                    this.$refs["heading" + this.open].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }, 200);
            }
        });
    },

    toggle(index) {
        this.open = this.open === index ? null : index;
    },

    get lastActiveCategory() {
        return this.$store.brandMenu.lastActiveCategory;
    },
});

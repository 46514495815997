export default (center) => ({
    center,
    map: null,
    zoomin: false,

    async loadMap() {
        const { default: mapboxgl } = await import("mapbox-gl");

        mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;

        this.map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/noordermeer/clkqmqucj008j01o8ayic3zdc",
            center,
            zoom: 16,
            minZoom: 10,
            maxZoom: 17,
            attributionControl: false,
            // dragPan: false,
        });

        const el = document.createElement("div");
        el.className = "marker";

        // Create a default Marker and add it to the map.
        const marker = new mapboxgl.Marker({
            element: el,
            offset: [0, -50],
        })
            .setLngLat(center)
            .addTo(this.map);
    },
    leave() {
        this.zoomin = false;

        if (this.map === null) {
            return;
        }

        this.map.flyTo({
            center,
            zoom: 16,
        });
    },

    destroy() {
        if (this.map) {
            this.map.remove();
        }
    },
});

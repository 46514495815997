import swup from "../swup";

export default () => ({
    loadedStories: 0,

    init() {
        this.moveLoadedStories(false);

        swup.hooks.on("content:replace", this.swupCallback.bind(this));
    },

    destroy() {
        swup.hooks.off("content:replace", this.swupCallback);
    },

    swupCallback(visit) {
        this.moveLoadedStories();
    },

    moveLoadedStories(remove = true) {
        const target = this.$el;
        const upcoming = document.querySelector("#stories");

        if (!upcoming || !target) {
            return;
        }

        const stories = upcoming.querySelectorAll(".story");

        // move stories to target
        Array.from(stories)
            .splice(this.loadedStories)
            .forEach((story) => {
                target.appendChild(story);
                this.loadedStories++;
            });

        // remove them from upcoming
        upcoming.innerHTML = "";
    },
});

import mixitup from "mixitup";

export default () => ({
    mixer: null,
    initialised: false,

    async init() {
        // const { default: mixitup } = await import('mixitup');

        this.mixer = mixitup(this.$el, {
            selectors: {
                target: ".mix",
            },
            load: {
                filter: this.currentFilter,
            },
            animation: {
                duration: 600,
            },
        });

        this.$watch("$store.brandMenu.selectedSlug", (selectedSlug) => {
            if (selectedSlug !== null) {
                this.mixer.filter(this.currentFilter);
            }
        });

        this.initialised = true;
    },

    destroy() {
        if (this.mixer) {
            this.mixer.destroy();
        }
    },

    get currentFilter() {
        return ".cat-" + this.$store.brandMenu.selectedSlug;
    },
});

import swup, {
    brandCategoryPattern,
    brandEntryPattern,
    homePattern,
    homeWithQueryPattern,
    searchPattern,
} from "../swup";
import { match } from "path-to-regexp";
import anime from "animejs";

export default {
    currentUri: "",
    lastCategory: null,
    fadedDesktopNav: false,
    fadeTimeout: null,

    init() {
        const brandMenuDesktop = document.querySelector(".brandMenuDesktop");

        this.setSelectedByCurrentUrl();

        this.lastCategory = this.selectedCategory;

        swup.hooks.on("visit:start", (visit) => {
            // store previous state
            const fromIsOnHome = this.isOnHome;

            // this.previousCategory = this.selectedCategory ?? this.previousCategory;
            // console.log('selectedCategory', this.selectedCategory);
            // console.log('selectedSlug', this.selectedSlug);

            // update current state
            this.setSelectedByUrl(visit.to.url);

            // if we're navigating to the home page, fade out the desktop nav
            if (this.isOnHome && visit.animation.animate) {
                brandMenuDesktop.classList.add("is-fading-out");
                this.fadedDesktopNav = true;
                return true;
            }

            // if we're navigating from the home page to a brand category page
            // and have not yet scrolled down, hide and fade in the desktop nav
            const scrollTop =
                window.scrollY ||
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
            if (
                fromIsOnHome &&
                this.showBrandMenu &&
                visit.animation.animate &&
                scrollTop < window.innerHeight - 60
            ) {
                visit.animation.name = "brand";
            }
        });

        // remove the classes after navigation
        swup.hooks.on("visit:end", () => {
            if (this.fadedDesktopNav) {
                clearTimeout(this.fadeTimeout);
                this.fadeTimeout = setTimeout(() => {
                    brandMenuDesktop.classList.remove("is-fading-out");
                    this.fadedDesktopNav = false;
                }, 500);
            }

            this.lastCategory = this.selectedCategory || this.lastCategory;

            // console.log('end: selectedCategory', this.selectedCategory);
            // console.log('end: selectedSlug', this.selectedSlug);
        });
    },

    setSelectedByUrl(url) {
        this.currentUri = url;
    },

    setSelectedByCurrentUrl() {
        const url = swup.getCurrentUrl();
        this.setSelectedByUrl(url);
    },

    uriIsHome(uri) {
        return match(homeWithQueryPattern)(uri) !== false;
    },

    get matchedBrandCategories() {
        return match(brandCategoryPattern, {
            decode: decodeURIComponent,
        })(this.currentUri);
    },

    get selectedItem() {
        if (this.isOnHome || this.isOnSearchPage) {
            return "__home__";
        } else if (this.isOnBrandsPage) {
            return "brandList";
        }

        return this.matchedBrandCategories?.params?.category ?? null;
    },

    get selectedChild() {
        return this.matchedBrandCategories?.params?.subcategory ?? null;
    },

    get isOnBrandCategoryPage() {
        return match(brandCategoryPattern)(this.currentUri) !== false;
    },

    get isOnBrandsPage() {
        return match(brandEntryPattern)(this.currentUri) !== false;
    },

    get isOnSearchPage() {
        return match(searchPattern)(this.currentUri) !== false;
    },

    get isOnHome() {
        return this.uriIsHome(this.currentUri);
    },

    get showBrandMenu() {
        return (
            this.isOnHome ||
            this.isOnBrandCategoryPage ||
            this.isOnBrandsPage ||
            this.isOnSearchPage
        );
    },

    get selectedSlug() {
        return this.isOnBrandCategoryPage
            ? this.selectedChild
                ? `${this.selectedItem}_${this.selectedChild}`
                : this.selectedItem
            : null;
    },

    get selectedCategory() {
        return this.isOnBrandCategoryPage
            ? this.selectedChild || this.selectedItem
            : null;
    },

    get lastActiveCategory() {
        return this.selectedCategory || this.lastCategory;
    },
};

export default {
    showDesktopNav: null,
    sm: null,

    showDesktopNavMediaQuery: window.matchMedia("(min-width: 1160px)"),
    smMediaQuery: window.matchMedia("(min-width: 640px)"),

    init() {
        this.sm = this.smMediaQuery.matches;
        this.smMediaQuery.addEventListener(
            "change",
            (e) => (this.sm = e.matches),
        );

        this.showDesktopNav = this.showDesktopNavMediaQuery.matches;
        this.showDesktopNavMediaQuery.addEventListener(
            "change",
            (e) => (this.showDesktopNav = e.matches),
        );
    },
};

import swup from "../swup";

export default () => ({
    init() {
        // todo: only scroll if we're below the header
        // swup.hooks.on('content:replace', (visit) => {
        //     if(visit.fragmentVisit) {
        //         const brandGrid = document.querySelector('.brandGrid');
        //         if(brandGrid) {
        //             brandGrid.scrollIntoView({ behavior: 'smooth' });
        //         }
        //     }
        // });
    },

    itemIsSelected(slug) {
        return this.$store.brandMenu.selectedItem === slug;
    },

    childIsSelected(slug) {
        return this.$store.brandMenu.selectedChild === slug;
    },

    get show() {
        return (
            this.$store.breakpoint.showDesktopNav &&
            this.$store.brandMenu.showBrandMenu
        );
    },

    get isOnBrandCategoryPage() {
        return this.$store.brandMenu.isOnBrandCategoryPage;
    },
});

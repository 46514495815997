import swup from "../swup";

export default () => ({
    open: false,
    query: "",

    init() {
        this.$watch("open", () => {
            if (this.open) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            } else {
                this.query = "";
            }
        });
    },

    toggle(e) {
        this.query = e.detail.query ?? "";
        this.open = true;
    },

    submit(e) {
        this.open = false;

        if (this.query.length === 0) {
            return;
        }

        if (window.plausible) {
            window.plausible("Search", {
                props: {
                    query: this.query,
                },
            });
        }

        swup.navigate(`${e.target.closest("form").action}?q=${this.query}`);
    },
});

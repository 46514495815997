// import "@dotlottie/player-component";
import animationData from "../../svg/subscribed.json";

export default () => ({
    open: false,
    form: {
        firstName: "",
        lastName: "",
        email: "",
    },
    showError: false,
    error: false,
    submitting: false,
    submitted: false,

    animationLoaded: false,
    lottieData: JSON.stringify(animationData),
    animationHalfway: false,

    init() {
        this.$watch("open", () => {
            if (this.open) {
                this.loadAnimation();
            }

            // Reset the form after closing it after a submit
            if (!this.open && this.submitted) {
                setTimeout(() => {
                    this.form = {
                        firstName: "",
                        lastName: "",
                        email: "",
                    };
                    this.submitted = false;
                    this.animationHalfway = false;
                    this.resetAnimation();
                }, 200);
            }
        });
    },

    toggle(e) {
        this.open = true;
    },

    async submit(e) {
        if (this.submitting) {
            return;
        }

        if (this.submitted) {
            this.open = false;
            return;
        }

        // keep the form open
        this.open = true;

        // mark as submitting
        this.submitting = true;

        this.playAnimation();

        const formElement = this.$refs.form;
        const formData = new FormData(formElement);

        const csrfToken = await fetch("/actions/blitz/csrf/token").then(
            (result) => {
                return result.text();
            },
        );

        // add csrf to form data
        formData.append(window.csrfTokenName, csrfToken);

        fetch("/actions/campaign/forms/subscribe", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                this.submitting = false;

                if (result.errors || result.error) {
                    this.showError = true;
                    this.error =
                        result.errors?.email?.length > 0 ? "email" : "other";
                    this.resetAnimation();
                    return;
                }

                this.finishAnimation();
                this.submitted = true;
            });
    },

    async loadAnimation() {
        if (this.animationLoaded) {
            return;
        }
        const animationData = await import("../../svg/subscribed.json");
        this.lottieData = JSON.stringify(animationData);
        await import("@dotlottie/player-component");
        this.animationLoaded = true;
    },

    async playAnimation() {
        if (!this.animationLoaded) {
            await this.loadAnimation();
        }

        this.$refs.subscribePlayer.play();
        setTimeout(() => {
            if (this.submitting) {
                this.$refs.subscribePlayer.pause();
            }
        }, 500);
    },

    resetAnimation() {
        if (!this.animationLoaded) {
            return;
        }

        this.$refs.subscribePlayer.stop();
        this.$refs.subscribePlayer.reset();
    },

    finishAnimation() {
        this.animationHalfway = true;
        this.$refs.subscribePlayer.play();
    },

    onClickError() {
        this.showError = false;
        this.open = true;
        this.$refs.emailInput.focus();
    },
});

import { flare } from "@flareapp/flare-client";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/bgset/ls.bgset";

if (process.env.NODE_ENV === "production") {
    flare.light();
}

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

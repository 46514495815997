import swup from "../swup";

export default () => ({
    backUrl: null,

    init() {
        // if on the next visit, the brand menu won't be shown but on the
        // current visit it is, then store the current url as the back url

        swup.hooks.on(
            "visit:start",
            (visit) => {
                // if the brand menu is currently visible - before the visit,
                // update the back url
                if (this.$store.brandMenu.showBrandMenu) {
                    this.backUrl = visit.from.url;
                }
            },
            { before: true },
        );
    },

    get showBackLink() {
        return this.backUrl !== null && !this.$store.brandMenu.showBrandMenu;
    },

    get isOnHome() {
        return this.$store.brandMenu.isOnHome;
    },

    goBack() {
        swup.navigate(this.backUrl);
    },
});

import "swiper/css";
import "swiper/css/parallax";

export default () => ({
    swiper: null,

    init() {
        // this.initSwiper();
    },

    async initSwiper() {
        // console.log('init swiper');
        const { default: Swiper } = await import("swiper");
        const { Parallax, Keyboard, Autoplay } = await import("swiper/modules");

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Parallax, Keyboard, Autoplay],
            loop: true,
            parallax: true,
            keyboard: {
                enabled: true,
            },
            autoplay: {
                delay: 5000,
            },
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    next() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    },

    prev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    },
});

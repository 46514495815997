import Swup from "swup";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupMorphPlugin from "swup-morph-plugin";

export const brandCategoryPattern =
    "/(en|cn|de)/(products|produkte)/:category/:subcategory?(\\/?)(\\?.*)?";
export const brandEntryPattern = "/(en|cn|de)/brands(\\/?)(\\?.*)?";
export const homeWithQueryPattern = "/(en|de|cn)(\\/?)(\\?.*)?";
export const searchPattern = "/(en|de|cn)/(search|suche)(.*)?";
export const homePattern = "/(en|de|cn)(\\/?)";

export default new Swup({
    containers: ["#main", "#header"],
    plugins: [
        new SwupMorphPlugin({
            containers: ["#headSwitcher", "#footerSwitcher"],
        }),
        new SwupFragmentPlugin({
            rules: [
                {
                    from: brandCategoryPattern,
                    to: brandCategoryPattern,
                    containers: ["#header"],
                },
                {
                    from: homePattern + "\\?page=:num(\\d+)",
                    to: homePattern + "\\?page=:num(\\d+)",
                    containers: ["#stories", "#loadMoreStories"],
                },
                {
                    from: homePattern,
                    to: homePattern + "\\?page=:num(\\d+)",
                    containers: ["#stories", "#loadMoreStories"],
                },
                {
                    from: searchPattern,
                    to: searchPattern,
                    containers: ["#searchResults"],
                },
            ],
            // debug: true,
        }),
        new SwupPreloadPlugin(),
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
        new SwupProgressPlugin({
            delay: 700,
        }),
    ],
});
